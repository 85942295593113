jQuery(document).ready(function() {
    "use strict"
    if (jQuery('#yccc-map').length) {
        YCCCMap.initMap();
    }
});

var YCCCMap = {

    initMap: function(){

        var layers = [];

        // Only one infowindow at a time, so we initialize it here
        var infowindow = new google.maps.InfoWindow({
            maxWidth: 280
        });
        infowindow.setContent('');
        infowindow.open();
        infowindow.close();

        var mapBounds = new google.maps.LatLngBounds(
            // new google.maps.LatLng(41.901, -83.255),
            // new google.maps.LatLng(42.359, -82.285)
        );

        var map = new google.maps.Map(
            document.getElementById('yccc-map'), 
            {
                center: { lat: 42.166179, lng: -82.755862 },
                gestureHandling: 'greedy',
                minZoom: 5,
                maxZoom: 12,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoom: 10,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_LEFT
                },
                styles: [{ "featureType": "all", "elementType": "geometry.fill", "stylers": [{ "weight": "2.00" }] }, { "featureType": "all", "elementType": "geometry.stroke", "stylers": [{ "color": "#9c9c9c" }] }, { "featureType": "all", "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "landscape", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#7b7b7b" }] }, { "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#c8d7d4" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#070707" }] }, { "featureType": "water", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }]
            }
        );

        for (var i = 0; i < geoJSON.features.length; i++) {
            mapBounds.extend(new google.maps.LatLng(geoJSON.features[i].geometry.coordinates[1], geoJSON.features[i].geometry.coordinates[0]));
        }

        map.fitBounds(mapBounds);

        layers['companies'] = new google.maps.Data({ map: map });

        layers['companies'].addGeoJson(geoJSON, {

        });

        layers['companies'].setStyle( function( feature ) {

            var terms = feature.getProperty('terms');
            var icon_url = getIconUrl(terms);
            var anchor = new google.maps.Point(14, 14);
            var scaledSize = new google.maps.Size(28, 28);

            return {
                icon: {
                    url: icon_url,
                    scaledSize: scaledSize,
                    origin: new google.maps.Point(0, 0),
                    anchor: anchor
                },
                zIndex: 5,
                visible: true
            }
        });

        layers['companies'].addListener('click', function(event) {
            var title = event.feature.getProperty('title');
            var excerpt = event.feature.getProperty('excerpt');
            var permalink = event.feature.getProperty('permalink');

            var html = '<div class="yccc-infowindow">';

            html += '<a class="yccc-infowindow-title" href="' + permalink + '" target="_blank">' + title + '</a>';
            
            if( excerpt != '' ){
                html += '<p class="yccc-infowindow-excerpt">';
                html += excerpt;
                html += '</p>';
            }

            html += '<div class="yccc-infowindow-more"><a href="' + permalink + '" target="_blank">Go to Organization Profile</a></div>';

            html += '</div>';

            infowindow.setPosition(event.feature.getGeometry().get());
            infowindow.setContent(html);
            infowindow.open(map);

        });

        if(jQuery('#wpadminbar').length == 0){
            map.addListener('dragend', function() {
                if (mapBounds.contains(map.getCenter())) {
                    return;
                }
        
                var c = map.getCenter(),
                    x = c.lng(),
                    y = c.lat(),
                    maxX = mapBounds.getNorthEast().lng(),
                    maxY = mapBounds.getNorthEast().lat(),
                    minX = mapBounds.getSouthWest().lng(),
                    minY = mapBounds.getSouthWest().lat();
        
                if (x < minX) x = minX;
                if (x > maxX) x = maxX;
                if (y < minY) y = minY;
                if (y > maxY) y = maxY;
        
                map.setCenter(new google.maps.LatLng(y, x));
            });
        }


    }
};

/**
 * Hierarchically determine icon to use given company tags
 * @param {Array<String>} companyTags Array of tags to check
 */
function getIconUrl(companyTags) {
    // no tag default
    if (!companyTags) {
        return '/wp-content/themes/yccc/img/map-icon.png';
    } 
    
    if (companyTags.includes('featured')) {
        return '/wp-content/themes/yccc/img/map-icon-featured.png';
    } else if (companyTags.includes('non-profit-member')) {
        return '/wp-content/themes/yccc/img/map-icon-featured.png';
    } else if (companyTags.includes('government-member')) {
        return '/wp-content/themes/yccc/img/map-icon-government.png';
    } 
    
    // unhandled tag default
    else {
        return '/wp-content/themes/yccc/img/map-icon.png';
    }
}
